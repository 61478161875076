<template>
  <div class="farm-admin">
    <div class="admin-top">
      <div class="top-item">
        <div class="top-item-title">
          <div class="title">基地概况</div>
          <div class="more" @click="jumpPage('index')">
            <span>数字地图</span>
            <span class="el-icon-arrow-right"></span>
          </div>
        </div>
        <div class="top-item-main">
          <div class="info">
            <img
              class="img"
              :src="farmInfo.photo ? uploadImgs + farmInfo.photo : defaultImgs"
              alt=""
            />
            <div class="text">
              <div class="text1">{{ farmInfo.title }}</div>
              <div class="text2">
                负责人：{{ userInfo.uid }} 电话：{{ userInfo.phone }}
              </div>
            </div>
          </div>
          <div class="data">
            <div class="data-item">
              <div class="count">{{ (farmInfo.area * 0.0015).toFixed(0) }}</div>
              <div class="unit">基地面积(亩)</div>
            </div>
            <div class="data-item">
              <VueCountUp
                class="count"
                :start-value="0"
                :end-value="landCount"
                :duration="2"
                :decimals="0"
                :delay="1"
              />
              <div class="unit">地块数量(块)</div>
            </div>
            <div class="data-item">
              <VueCountUp
                class="count"
                :start-value="0"
                :end-value="zuowuTotal"
                :duration="2"
                :decimals="0"
                :delay="1"
              />
              <div class="unit">种养作物(种)</div>
            </div>
            <div class="data-item">
              <VueCountUp
                class="count"
                :start-value="0"
                :end-value="piciTotal"
                :duration="2"
                :decimals="0"
                :delay="1"
              />
              <div class="unit">种植批次(个)</div>
            </div>
          </div>
        </div>
      </div>
      <div class="top-item">
        <div class="top-item-title">
          <div class="title">智能设备</div>
        </div>
        <div class="equipment-main">
          <v-chart
            ref="statusChart"
            class="status-chart"
            autoresize
            :option="statusOption"
          ></v-chart>
          <div class="equipment-list">
            <div class="equipment-items">
              <span class="num" v-if="weatherCount != -1">{{
                qixiangCount
              }}</span>
              <span class="num" v-else>0</span>
              <span class="unit">气象站(个)</span>
            </div>
            <div class="equipment-items">
              <span class="num">{{ count }}</span>
              <span class="unit">摄像头(个)</span>
            </div>
            <div class="equipment-items">
              <span class="num">{{
                stat.all_count - count - qixiangCount
              }}</span>
              <span class="unit">其他(个)</span>
            </div>
          </div>
        </div>
      </div>
      <div class="top-item">
        <div class="top-item-title">
          <div class="title">智能控制</div>
          <div class="more" @click="jumpPage('chemical', 'gate')">
            <span>更多控制</span>
            <span class="el-icon-arrow-right"></span>
          </div>
        </div>
        <div class="equipment-main">
          <v-chart
            ref="statusChart"
            class="status-chart"
            autoresize
            :option="controlOption"
          ></v-chart>
          <div class="equipment-list">
            <div
              class="equipment-items control"
              v-for="(item, index) in kzlist"
              :key="index"
            >
              <span class="num">{{ item.num }}</span>
              <span class="unit">{{ item.unit }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="admin-bottom">
      <div class="admin-bottom-l">
        <div class="top-item">
          <div class="top-item-title">
            <div class="title">农事任务</div>
            <div class="more" @click="jumpPage('task')">
              <span>更多任务</span>
              <span class="el-icon-arrow-right"></span>
            </div>
          </div>
          <div class="top-item-main">
            <div class="info">
              <div class="img">
                <img src="../assets/image/admin/huan.png" alt="" />
                <div class="undone">
                  <VueCountUp
                    class="undone-count"
                    :start-value="0"
                    :end-value="taskStatistics.unfinished_count"
                    :duration="2"
                    :decimals="0"
                    :delay="1"
                  />
                  <div class="undone-unit">未完成(项)</div>
                </div>
              </div>

              <div class="text">
                <div class="text1">农事任务情况</div>
                <div class="text2">{{ farmInfo.title }}农事任务总计</div>
              </div>
            </div>
            <div class="data">
              <div class="data-item">
                <VueCountUp
                  class="count"
                  :start-value="0"
                  :end-value="taskStatistics.finished_count"
                  :duration="2"
                  :decimals="0"
                  :delay="1"
                />
                <div class="unit">已完成任务(项)</div>
              </div>
              <div class="data-item timeout">
                <VueCountUp
                  class="count"
                  :start-value="0"
                  :end-value="taskStatistics.overdue_count"
                  :duration="2"
                  :decimals="0"
                  :delay="1"
                />
                <div class="unit">已超期任务(项)</div>
              </div>
              <div class="data-item allot">
                <VueCountUp
                  class="count"
                  :start-value="0"
                  :end-value="taskStatistics.unabsorbed_count"
                  :duration="2"
                  :decimals="0"
                  :delay="1"
                />
                <div class="unit">未分配任务(项)</div>
              </div>
              <div class="data-item ing">
                <VueCountUp
                  class="count"
                  :start-value="0"
                  :end-value="taskStatistics.unfinished_count"
                  :duration="2"
                  :decimals="0"
                  :delay="1"
                />
                <div class="unit">进行中任务(项)</div>
              </div>
            </div>
          </div>
        </div>
        <div class="top-item">
          <div class="top-item-title">
            <div class="title">害虫监测数据</div>
            <div class="more" @click="jumpPage('chemical', 'insect')">
              <span>更多害虫</span>
              <span class="el-icon-arrow-right"></span>
            </div>
          </div>
          <div class="top-item-main">
            <div class="info">
              <div class="img">
                <img src="../assets/image/admin/huan.png" alt="" />
                <div class="undone">
                  <VueCountUp
                    class="undone-count"
                    :start-value="0"
                    :end-value="allWormNum"
                    :duration="2"
                    :decimals="0"
                    :delay="1"
                  />
                  <div class="undone-unit">全部害虫(只)</div>
                </div>
              </div>

              <div class="text">
                <div class="text1">害虫监测数据情况</div>
                <div class="text2">
                  {{ farmInfo.title }}{{ nowYear }}年害虫监测数据统计
                </div>
              </div>
            </div>
            <div class="data">
              <div
                class="data-item"
                v-for="(item, index) in wormList"
                :key="index"
              >
                <VueCountUp
                  class="count"
                  :start-value="0"
                  :end-value="item.value"
                  :decimals="0"
                />
                <div class="unit">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="admin-bottom-r">
        <div class="top-item-title">
          <div class="title">溯源追溯</div>
          <div class="more" @click="jumpPage('traceability')">
            <span>更多溯源</span>
            <span class="el-icon-arrow-right"></span>
          </div>
        </div>
        <div class="b-r-b">
          <div class="top">
            <div class="top-title">
              <span class="span1">溯源扫码地点统计分析</span>
              <span class="span2">TOP10</span>
            </div>
            <div class="top-list">
              <div
                class="top-list-items"
                v-for="(item, index) in cityTopList"
                :key="index"
              >
                <div class="top-list-items-top">
                  <span class="top-name">{{ item.name }}</span>
                  <div class="top-degree">
                    <VueCountUp
                      class="degree-count"
                      :start-value="0"
                      :end-value="item.value"
                      :duration="2"
                      :decimals="0"
                      :delay="1"
                    />
                    <span class="degree-unit">次</span>
                  </div>
                </div>
                <div class="top-list-items-line">
                  <el-slider
                    :value="item.value"
                    :max="productAllNum"
                    input-size="mini"
                    disabled
                  ></el-slider>
                </div>
              </div>
            </div>
          </div>
          <div class="china-charts">
            <div class="charts-top-statistics">
              <div class="statistics-items">
                <VueCountUp
                  class="statistics-count"
                  :start-value="0"
                  :end-value="trance_count"
                  :duration="2"
                  :decimals="0"
                  :delay="1"
                />
                <div class="statistics-unit">溯源扫码统计</div>
              </div>
              <div class="statistics-items">
                <VueCountUp
                  class="statistics-count"
                  :start-value="0"
                  :end-value="tranceProductCount"
                  :duration="2"
                  :decimals="0"
                  :delay="1"
                />
                <div class="statistics-unit">溯源产品</div>
              </div>
              <div class="statistics-items">
                <VueCountUp
                  class="statistics-count"
                  :start-value="0"
                  :end-value="piciTotal"
                  :duration="2"
                  :decimals="0"
                  :delay="1"
                />
                <div class="statistics-unit">溯源批次数</div>
              </div>
            </div>
            <div id="container"></div>
          </div>
          <div class="top">
            <div class="top-title">
              <span class="span1">溯源产品扫码分析</span>
              <span class="span2">TOP10</span>
            </div>
            <div class="top-list">
              <div
                class="top-list-items"
                v-for="(item, index) in productTopList"
                :key="index"
              >
                <div class="top-list-items-top">
                  <span class="top-name">{{ item.name }}</span>
                  <div class="top-degree">
                    <VueCountUp
                      class="degree-count"
                      :start-value="0"
                      :end-value="item.value"
                      :duration="2"
                      :decimals="0"
                      :delay="1"
                    />
                    <span class="degree-unit">次</span>
                  </div>
                </div>
                <div class="top-list-items-line">
                  <el-slider
                    :value="item.value"
                    :max="cityAllNum"
                    input-size="mini"
                    disabled
                  ></el-slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MyNav></MyNav>
  </div>
</template>

<script>
import jsonData from "@/assets/json/china.json";
import {
  getTranceStatisticApi,
  getEquipmentCountApi,
  getAllMarkDataApi,
  getBatchListApi,
  getProductClassListApi,
  getTranceProductListApi,
  getTaskStatisticsApi,
  getTaskListApi,
  getEquipmentGroupApi,
  getEquipmentInfoApi,
  getEquipmentListApi,
  getTranceTopListApi,
  getEquipmentChildListApi,
} from "@/request/api.js";
import { mapState, mapMutations } from "vuex";

import VueCountUp from "vue-countupjs";
import * as echarts from "echarts/core";
import {
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  TitleComponent,
  GridComponent,
} from "echarts/components";
import { PieChart, FunnelChart, LineChart } from "echarts/charts";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import VChart, { THEME_KEY } from "vue-echarts";
echarts.use([
  TooltipComponent,
  LegendComponent,
  PieChart,
  LabelLayout,
  ToolboxComponent,
  CanvasRenderer,
  TitleComponent,
  FunnelChart,
  GridComponent,
  LineChart,
  UniversalTransition,
]);
export default {
  components: {
    VueCountUp,
    VChart,
    MyNav: () => import("@/components/MyNav.vue"),
  },
  provide: {
    [THEME_KEY]: "dark",
  },
  data() {
    return {
      defaultImgs: require("@/assets/image/configure/farm/icon8.png"),
      stat: {},
      taskStatistics: {},

      insectTableId: "",
      gateTableId: "",
      nowYear: 0,
      weatherCount: 0,
      kongzhiCount: 0,
      allWormNum: 0,
      qixiangCount: 0,
      landCount: 0,
      piciTotal: 0,
      zuowuTotal: 0,
      tranceProductCount: 0,
      trance_count: 0,
      productAllNum: 0,
      cityAllNum: 0,
      dataList: [],
      kongzhiList: [],
      wormList: [],
      cameraList: [],
      equipmentGroupList: {},
      countList: [],
      cityTopList: [],
      productTopList: [],

      controlList: [
        { num: 0, unit: "正在运行控制(个)" },
        { num: 0, unit: "未启用控制(个)" },
        { num: 0, unit: "控制设备总数(个)" },
      ],
      statusOption: {
        backgroundColor: "transparent",
        tooltip: {
          trigger: "item",
        },

        color: [
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#31C283",
              },

              {
                offset: 1,
                color: "#77E0B7",
              },
            ],
            global: false,
          },
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#C188FE",
              },

              {
                offset: 1,
                color: "#A068FF",
              },
            ],
            global: false,
          },
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#5CA7F9",
              },

              {
                offset: 1,
                color: "#3D5FFE",
              },
            ],
            global: false,
          },
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#FEDE37", // 0% 处的颜色
              },
              {
                offset: 0.5,
                color: "#FCAD18", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "#FFF344", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        ],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["80%", "95%"],

            avoidLabelOverlap: true, //防止重叠
            showEmptyCircle: true, //占位
            itemStyle: {
              borderColor: "#112945",
              borderRadius: 10,
              borderWidth: 3,
            },

            label: {
              show: true,
              position: "center",
              formatter: "",
              textStyle: {
                rich: {
                  c: {
                    fontSize: 30,
                    fontWeight: "bold",
                    color: "#fff",
                  },
                  d: {
                    fontSize: 12,
                    fontWeight: "400",
                    color: "#fff",
                  },
                },
              },
            },

            emphasis: {
              label: {
                show: false,
              },
            },
            data: [
              { value: 0, name: "气象站(个)" },
              { value: 0, name: "摄像头(个)" },
              { value: 0, name: "其他(个)" },
            ],
          },
        ],
      },
      controlOption: {
        backgroundColor: "transparent",
        tooltip: {
          trigger: "item",
        },
        grid: {
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
        },
        color: [
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#2EC080",
              },

              {
                offset: 1,
                color: "#7CE3BB",
              },
            ],
            global: false,
          },
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#FF6262",
              },

              {
                offset: 1,
                color: "#FFA3A3",
              },
            ],
            global: false, 
          },
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#A169FF",
              },

              {
                offset: 1,
                color: "#BD84FE",
              },
            ],
            global: false,
          },
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#436CFD",
              },

              {
                offset: 1,
                color: "#5599FA",
              },
            ],
            global: false,
          },
        ],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["80%", "95%"],
            avoidLabelOverlap: true, //防止重叠
            showEmptyCircle: true, //占位
            itemStyle: {
              borderColor: "#112945",
              borderRadius: 10,
              borderWidth: 3,
            },

            label: {
              show: true,
              position: "center",
              formatter: "{c|" + 0 + "} \n {d|控制设备总数(个)}",
              textStyle: {
                rich: {
                  c: {
                    fontSize: 30,
                    fontWeight: "bold",
                    color: "#fff",
                  },
                  d: {
                    fontSize: 12,
                    fontWeight: "400",
                    color: "#fff",
                  },
                },
              },
            },

            emphasis: {
              label: {
                show: false,
              },
            },
            data: [
              { value: 0, name: "正在运行控制(个)" },
              { value: 0, name: "未启用控制(个)" },
            ],
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      uploadImgs: (state) => state.app.uploadImgs,
      farmID: (state) => state.user.farmID,
      farmInfo: (state) => state.user.farmInfo,
      userInfo: (state) => state.user.userInfo,
    }),
    count() {
      let videoCount = 0;
      let num = this.cameraList.findIndex((item) => item.group === "视频监控");
      videoCount =
        num === -1
          ? 0
          : this.cameraList[
              this.cameraList.findIndex((item) => item.group === "视频监控")
            ].count;
      return videoCount;
    },
    kzlist() {
      let qiyong = 0;
      let weiqiyong = 0;
      this.kongzhiList.forEach((item, index) => {
        this.controlOption.series[0].label.formatter = `{c|${
          index + 1
        }}\n{d|控制设备总数(个)}`;
        this.controlList[2].num = index + 1;

        if (item.last_data) {
          qiyong++;
          this.controlList[0].num = qiyong;
          this.controlOption.series[0].data[0].value = qiyong;
        } else {
          weiqiyong++;
          this.controlList[1].num = weiqiyong;
          this.controlOption.series[0].data[1].value = weiqiyong;
        }
      });

      return this.controlList;
    },
  },
  created() {
    this.getAllMarkData();
    this.getTranceStatisticApi();
    this.getProductClassListApi();
    this.getBatchListApi();
    this.getTranceProductListApi();
    this.getTaskStatisticsApi();
    this.getEquipmentGroupApi();
    this.getTranceCityTopListApi();
    this.getTranceProductTopListApi();
  },
  methods: {
    ...mapMutations("user", ["SET_EQUIPMENT_Name"]),
    jumpPage(name, icon) {
      if (name == "chemical") {
        let id = "";
        if (icon == "gate") {
          id = this.gateTableId;
        } else {
          id = this.insectTableId;
        }
        sessionStorage.setItem("childTitle3", id);
        sessionStorage.setItem("equipmentId", id);
        sessionStorage.setItem("equipmentName", icon);

        this.SET_EQUIPMENT_Name({ icon });

        this.$router.push({
          name,
          query: { id },
        });
      } else {
        this.$router.push({
          name,
        });
      }
    },
    async getTranceCityTopListApi() {
      const { code, results } = await getTranceTopListApi({
        manor_id: this.farmID,
        top_type: 0,
        ps: 10,
        pn: 1,
      });
      if (code != 0) return;
      results.data.forEach((item) => {
        this.cityAllNum += item.value;
      });
      this.cityTopList = results.data;
    },
    async getTranceProductTopListApi() {
      const { code, results } = await getTranceTopListApi({
        manor_id: this.farmID,
        top_type: 1,
        ps: 10,
        pn: 1,
      });
      if (code != 0) return;
      results.data.forEach((item) => {
        this.productAllNum += item.value;
      });
      this.productTopList = results.data;
    },
    async getEquipmentGroupApi() {
      const { code, data } = await getEquipmentGroupApi();
      if (code !== 200) return;

      for (const key in data) {
        // 虫情设备
        if (data[key].icon == "insect") {
          this.insectTableId = data[key].id;
          this.getQuantityEquipmentListApi(data[key].id);
        } else if (data[key].icon == "gate") {
          // 阀门控制
          this.gateTableId = data[key].id;
          this.getGateEquipmentListApi(data[key].id);
        }
      }
    },

    async getEquipmentInfoApi(table_id) {
      this.nowYear = +new Date().getFullYear();
      let month = +new Date().getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      let day = +new Date().getDate();
      day = day < 10 ? "0" + day : day;
      const { code, results } = await getEquipmentInfoApi({
        manor_id: this.farmID,
        table_id,
        day: `${this.nowYear}-01-01`,
        end_day: `${this.nowYear}-${month}-${day}`,
        act: "status",
      });
      if (code !== 0) return;
      let item = {};
      let wormList = [];
      for (let i = 0; i < results.data.length; i++) {
        this.allWormNum += results.data[i].num;
        item[results.data[i].name] =
          ~~item[results.data[i].name] + results.data[i].num;
      }
      let keys = Object.keys(item);
      let values = Object.values(item);
      for (let k = 0; k < keys.length; k++) {
        let obj = {};
        obj.name = keys[k];
        obj.value = values[k];
        wormList.push(obj);
        if (wormList.length == keys.length) {
          let arr = [];
          arr = wormList.sort((a, b) => {
            return b.value - a.value;
          });
          this.wormList = arr.slice(0, 8);
        }
      }
    },
    async getGateEquipmentListApi(channel_id) {
      const { code, results } = await getEquipmentListApi({
        manor_id: this.farmID,
        channel_id,
      });
      if (code === 0 && Array.isArray(results)) {
        results.forEach((t) => {
          let table_id = t.id;
          this.getEquipmentChildListApi(table_id);
        });
      }
    },

    async getEquipmentChildListApi(table_id) {
      const { code, results } = await getEquipmentChildListApi({
        manor_id: this.farmID,
        table_id,
      });
      if (code === 0 && results) {
        results.forEach((item) => {
          if (item.type === 1) {
            this.kongzhiList.push(item);
          }
        });
      }
    },
    async getQuantityEquipmentListApi(channel_id) {
      const { code, results } = await getEquipmentListApi({
        manor_id: this.farmID,
        channel_id,
      });
      if (code === 0 && Array.isArray(results)) {
        let table_id = results.find(
          (item) => item.alias === "insect-quantity"
        ).id;
        this.getEquipmentInfoApi(table_id);
      }
    },
    async getTaskListApi() {
      const { code, results } = await getTaskListApi({
        manor_id: this.farmID,
      });
      if (code != 0) return;
      let renwuCount = results.count;
      return renwuCount;
    },
    async getTaskStatisticsApi() {
      const { code, results } = await getTaskStatisticsApi({
        manor_id: this.farmID,
      });
      if (code != 0) return;
      this.taskStatistics = results;
      this.taskStatistics.allCount = await this.getTaskListApi();
    },
    async getTranceProductListApi() {
      const { code, results } = await getTranceProductListApi({
        manor_id: this.farmID,
      });
      if (code != 0) return;
      this.tranceProductCount = results.count;
    },
    async getProductClassListApi() {
      const { code, results } = await getProductClassListApi({
        manor_id: this.farmID,
      });
      if (code !== 0) return;
      this.zuowuTotal = results.count;
    },
    // 获取批次列表
    async getBatchListApi() {
      const { code, results } = await getBatchListApi({
        manor_id: this.farmID,
      });
      if (code !== 0) return;
      this.piciTotal = results.count;
    },
    async getAllMarkData() {
      const { code, results } = await getAllMarkDataApi({
        manor_id: this.farmID,
      });
      if (code != 0) return;
      this.cameraList = results;
      this.weatherCount = this.cameraList.findIndex(
        (item) => item.group === "气象采集"
      );
      this.landCount = this.cameraList.find(
        (item) => item.group === "地块"
      ).count;
      this.qixiangCount = this.cameraList.find(
        (item) => item.group === "气象采集"
      ).count;
      this.getEquipmentCountApi();
    },
    async getEquipmentCountApi() {
      const { code, data } = await getEquipmentCountApi({
        manor_id: this.farmID,
      });
      if (code !== 200) return;
      this.stat = data;
      this.statusOption.series[0].label.formatter = `{c|${data.all_count}}\n{d|设备总数(个)}`;
      this.statusOption.series[0].data[0].value = this.qixiangCount;
      this.statusOption.series[0].data[1].value = this.count;
      this.statusOption.series[0].data[2].value =
        this.stat.all_count - this.count - this.qixiangCount;
      this.$forceUpdate();
    },
    initChinaEChart() {
      let max = 6000,
        min = 10,
        maxSize4Pin = 100,
        minSize4Pin = 20;
      let myChart = this.$echarts.init(document.getElementById("container"));
      this.$echarts.registerMap("china", jsonData);
      let option = {
        backgroundColor: "#0e2138",
        tooltip: {
          show: true,
          padding: [5],
          backgroundColor: "#0E2138",
          borderWidth: 0,
          textStyle: {
            color: "#fff",
          },
          formatter: function (params) {
            if (params.value.length > 1) {
              return (
                "&nbsp;&nbsp;" +
                params.name +
                "&nbsp;&nbsp;&nbsp;" +
                params.value[2] +
                "&nbsp;次&nbsp;&nbsp;"
              );
            } else {
              return (
                "&nbsp;&nbsp;" +
                params.name +
                "&nbsp;&nbsp;&nbsp;" +
                0 +
                "&nbsp;次&nbsp;&nbsp;"
              );
            }
          },
        },

        geo: {
          map: "china",
          type: "map",
          zoom: 1.15, //缩放比例
          show: true,
          roam: false, //禁止滚动等
          label: {
            emphasis: {
              show: false,
            },
          },
          layoutSize: "100%",
          itemStyle: {
            normal: {
              borderColor: this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "#00F6FF",
                  },
                  {
                    offset: 1,
                    color: "#53D9FF",
                  },
                ],
                false
              ),

              borderWidth: 3,
              shadowColor: "rgba(10,76,139,1)",
              shadowOffsetY: 0,
              shadowBlur: 60,
            },
          },
        },

        series: [
          {
            type: "map",
            map: "china",
            aspectScale: 0.75,
            zoom: 1.15,
            label: {
              normal: {
                show: false,
              },
              emphasis: {
                show: false,
              },
            },
            itemStyle: {
              normal: {
                areaColor: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#073684", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#061E3D", // 100% 处的颜色
                    },
                  ],
                },
                borderColor: "#215495",
                borderWidth: 1,
              },
              emphasis: {
                areaColor: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#073684", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#061E3D", // 100% 处的颜色
                    },
                  ],
                },
              },
            },
            data: [],
          },
          {
            type: "effectScatter",
            coordinateSystem: "geo",
            rippleEffect: {
              brushType: "stroke",
            },
            showEffectOn: "render",
            itemStyle: {
              normal: {
                color: {
                  type: "radial",
                  x: 0.5,
                  y: 0.5,
                  r: 0.5,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(5,80,151,0.2)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(5,80,151,0.8)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0,108,255,0.7)",
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
            label: {
              normal: {
                show: true,
                color: "#fff",
                fontWeight: "bold",
                position: "inside",
                formatter: function (para) {
                  return "{cnNum|" + para.data.value[2] + "}";
                },
                rich: {
                  cnNum: {
                    fontSize: 13,
                    color: "#D4EEFF",
                  },
                },
              },
            },
            symbol: "circle",
            symbolSize: function (val) {
              if (val[2] === 0) {
                return 0;
              }
              let a = (maxSize4Pin - minSize4Pin) / (max - min);
              let b = maxSize4Pin - a * max;
              return a * val[2] + b * 1.2;
            },
            data: this.dataList,
            zlevel: 1,
          },
        ],
      };
      myChart.setOption(option);
    },
    async getTranceStatisticApi(search_date = undefined, pid = undefined) {
      const { code, results } = await getTranceStatisticApi({
        manor_id: this.farmID,
        search_date,
        pid,
      });
      if (code != 0) return;
      this.trance_count = results.trance_count;

      results.data.forEach((item) => {
        let obj = {
          name: "",
          value: [],
        };
        obj.name = item.name;
        obj.value[0] = +item.position.split(",")[0];
        obj.value[1] = +item.position.split(",")[1];
        obj.value[2] = item.value;
        this.dataList.push(obj);
      });
      this.initChinaEChart();
    },
  },
};
</script>

<style lang="less" scoped>
.farm-admin {
  width: 100%;
  min-height: 100vh;
  background-color: #071a2c;
  padding: 78px 16px 16px;
  .admin-top {
    width: 100%;
    height: 301px;
    margin-bottom: 16px;
    display: flex;
    .top-item {
      height: 100%;
      width: 618px;
      background: #0e2138;
      margin: 0 16px 0 0;
      &:last-of-type {
        margin: 0;
      }
      .top-item-title {
        height: 100%;
        height: 56px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 18px;
        .title {
          font-size: 16px;
          color: #fff;
          padding: 0 13px;
          position: relative;
          &::before {
            content: "";
            width: 2px;
            height: 15px;
            background: #3e90e5;
            border-radius: 1px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
        }
        .more {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:hover {
            span {
              color: #3e90e5;
            }
          }
          span {
            font-size: 12px;
            color: #d1e5fa;
          }
          span.el-icon-arrow-right {
            font-size: 16px;
            line-height: initial;
          }
        }
      }
      .top-item-main {
        width: 100%;
        padding: 0 37px;
        .info {
          width: 100%;
          padding: 25px 0;
          display: flex;
          align-items: center;

          .img {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            margin-right: 19px;
            object-fit: cover;
          }
          .text {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .text1 {
              font-size: 16px;
              font-weight: bold;
              margin-bottom: 15px;
              color: #fff;
            }
            .text2 {
              font-size: 12px;
              color: #d1e5fa;
            }
          }
        }
        .data {
          width: 100%;
          display: flex;

          .data-item {
            width: 25%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .count {
              font-size: 24px;
              font-weight: bold;
              color: #3e90e5;
            }
            .unit {
              font-size: 12px;
              font-weight: 400;
              color: #d1e5fa;
            }
            &.timeout {
              .count {
                color: #fcae19;
              }
            }
            &.allot {
              .count {
                color: #1aade4;
              }
            }
            &.ing {
              .count {
                color: #22bb78 !important;
              }
            }
          }
        }
      }
      .equipment-main {
        width: 100%;
        height: calc(100% - 56px);
        padding: 40px 50px;
        display: flex;
        justify-content: space-between;
        .status-chart {
          width: 164px;
          height: 164px;
        }
        .equipment-list {
          height: 164px;
          width: 60%;
          display: flex;
          flex-wrap: wrap;
          .equipment-items {
            width: 50%;
            height: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            span.num {
              font-size: 24px;
              font-weight: bold;
              color: #3e90e5;
            }
            span.unit {
              font-size: 12px;
              font-weight: 400;
              margin-bottom: 0;
              color: #d1e5fa;
            }
            &.control {
              &:nth-of-type(1) {
                span.num {
                  color: #22bb78;
                }
              }
              &:nth-of-type(2) {
                span.num {
                  color: #f34646;
                }
              }
            }
          }
        }
      }
    }
  }
  .admin-bottom {
    width: 100%;
    height: 732px;
    display: flex;
    .admin-bottom-l {
      width: 618px;
      height: 100%;
      background: #3e90e5;
      margin: 0 16px 0 0;
      display: flex;
      flex-direction: column;
      .top-item {
        height: 100%;
        width: 618px;
        background: #0e2138;
        margin: 0 16px 0 0;
        &:last-of-type {
          margin: 0;
        }
        .top-item-title {
          height: 100%;
          height: 56px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 18px;
          .title {
            font-size: 16px;
            color: #fff;
            padding: 0 13px;
            position: relative;
            &::before {
              content: "";
              width: 2px;
              height: 15px;
              background: #3e90e5;
              border-radius: 1px;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
          }
          .more {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover {
              span {
                color: #3e90e5;
              }
            }
            span {
              font-size: 12px;
              color: #d1e5fa;
            }
            span.el-icon-arrow-right {
              font-size: 16px;
              line-height: initial;
            }
          }
        }
        .top-item-main {
          width: 100%;
          padding: 0 37px;
          .info {
            width: 100%;
            padding: 25px 0;
            display: flex;
            align-items: center;

            .img {
              width: 108px;
              height: 108px;
              border-radius: 50%;
              margin-right: 19px;
              position: relative;
              img {
                width: 108px;
                height: 108px;
                top: 0;
                left: 0;
              }
              .undone {
                width: 100%;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                .undone-count {
                  font-size: 24px;
                  font-weight: bold;
                  color: #dbedff;
                }
                .undone-unit {
                  font-size: 12px;
                  font-weight: 400;
                  color: #d1e5fa;
                }
              }
            }
            .text {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              .text1 {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 15px;
                color: #fff;
              }
              .text2 {
                font-size: 12px;
                color: #d1e5fa;
              }
            }
          }
          .data {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .data-item {
              width: 25%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .count {
                font-size: 24px;
                font-weight: bold;
                color: #3e90e5;
              }
              .unit {
                font-size: 12px;
                font-weight: 400;
                color: #d1e5fa;
              }
            }
          }
        }
      }
    }
    .admin-bottom-r {
      height: 100%;
      width: calc(100% - 634px);
      background: #0e2138;

      .top-item-title {
        height: 56px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 18px;
        .title {
          font-size: 16px;
          color: #fff;
          padding: 0 13px;
          position: relative;
          &::before {
            content: "";
            width: 2px;
            height: 15px;
            background: #3e90e5;
            border-radius: 1px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
        }
        .more {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:hover {
            span {
              color: #3e90e5;
            }
          }
          span {
            font-size: 12px;
            color: #d1e5fa;
          }
          span.el-icon-arrow-right {
            font-size: 16px;
            line-height: initial;
          }
        }
      }
      .b-r-b {
        width: 100%;
        height: calc(100% - 56px);
        display: flex;
        padding: 27px 0 0 0;
        .top {
          width: 291px;
          height: 621px;
          background: #122945;

          .top-title {
            width: 100%;
            height: 50px;
            line-height: 50px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            padding: 0 14px;
            .span1,
            .span2 {
              font-size: 16px;
              font-weight: 400;
              color: #fff;
            }
            .span2 {
              font-weight: bold;
            }
          }
          .top-list {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 26px 14px;
            .top-list-items {
              width: 100%;
              display: flex;
              flex-direction: column;
              margin-bottom: 22px;
              .top-list-items-top {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 12px;
                .top-name {
                  font-size: 12px;
                  font-weight: 400;
                  color: #fff;
                }
                .top-degree {
                  font-size: 12px;
                  font-weight: bold;
                  .degree-count {
                    color: #3e90e5;
                  }
                  .degree-unit {
                    margin: 0 0 0 4px;
                    color: #fff;
                  }
                }
              }
              /deep/.top-list-items-line {
                width: 100%;
                height: 5px;
                border-radius: 3px;
                .el-slider__runway {
                  margin: 0;
                  height: 5px;
                  background: #1d426c;

                  .el-slider__bar {
                    height: 100%;
                    background: #3e90e5;
                  }
                  .el-slider__button-wrapper {
                    display: none;
                  }
                }
                .el-slider__runway.disabled .el-slider__button.dragging,
                .el-slider__runway.disabled .el-slider__button.hover,
                .el-slider__runway.disabled .el-slider__button:hover {
                  cursor: default;
                }
              }
            }
          }
        }
        .china-charts {
          // width: 1070px;
          // height: 100%;
          flex: 1;
          background: #0e2138;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          .charts-top-statistics {
            width: 100%;
            display: flex;
            .statistics-items {
              width: 33.33%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .statistics-count {
                font-size: 30px;
                font-weight: bold;
                color: #3e90e5;
                margin-bottom: 14px;
              }
              .statistics-unit {
                font-size: 12px;
                font-weight: 400;
                color: #fff;
              }
            }
          }
          #container {
            // width: 1070px;
            // height: calc(100% - 40px);
            flex: 1;
          }
        }
      }
    }
  }
}
</style>
